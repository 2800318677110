
import { defineComponent } from "vue";
import * as Vue from 'vue'
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from 'element-plus';
import { apiEndpoint } from "@/mixin/apiEndpoint";
import Vue3Html2pdf from "vue3-html2pdf";
import Certificate from "@/layout/extras/Certificate.vue";
import { VueCookieNext } from "vue-cookie-next";
import moment from 'moment';
import QrcodeVue from 'qrcode.vue';
import jsPDF from 'jspdf'
import html2pdf from 'html2pdf.js'


export default defineComponent({

  mixins: [apiEndpoint],
  name: "new-entity-course",
  Vue,
  components: {
    Certificate,
    Datatable,
    Vue3Html2pdf,
    QrcodeVue,
    ElNotification,


  },
  data() {
    return {
      institute_id: '',
      link: '',
      qrData: '' as any,
      api_url: '',
      moment: '' as any,
      id: this.$route.params.id,
      employee: {},
      details: {},
      weekplan: {},
      UnitOfCompetencyKey: 0,
      course_name: "" as any,
      course_end: "" as any,
      course_start: "" as any,
      course_id: "" as any,
      entity_description: "" as any,
      entity_name: "" as any,
      entity_short_name: "" as any,
      registration_number: "" as any,
      reference_number: "" as any,
      name: "" as any,
      hour: "" as any,
      address: "" as any,
      isLoading: true,
      imageLink: "media/logos/logo-1.svg",
      logoBangladesh: "media/logos/bangladesh-govt-logo-vector.svg",
      backgroundImageCertificate: 'media/logos/6.gif',
      entityTypes: [],
      industry: [],
      $refs: {} as any,
      tranches_info: [],
      batchList: [],
      father_name: "" as any,
      mother_name: "" as any,
      certification_date: "" as any,
      qrCodeImageUrl: "" as any,
      divisions: [],
      traineeList: [] as any,
      districts: [],
      subDistricts: [],
      instituteList: [],
      unitOfCompetencyList: [] as any,
      courseList: [],
      entity_id: "" as any,
      attach_file: "" as any,
      empDistricts: [],
      BackgroundInfos: {
        left_signatory: '',
        middle_signatory: '',
        right_signatory: '',
        background_image: ''
      } as any,
      entityInfos: {
        entity_name: '',
        entity_short_name: '',
        image_file_name: '' as any,
      } as any,
    };
  },
  async created() {
    this.moment = moment;
    this.isLoading = true;

    await this.initData();
    this.api_url = this.VUE_APP_API_URL;

  },
  methods: {
    async onProgress({ html2pdf, options, pdfContent }) {
      console.log("onProgress")
      console.log(html2pdf)
      console.log(options)
      console.log(pdfContent)
      // await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
      //     const totalPages = pdf.internal.getNumberOfPages()
      //     for (let i = 1; i <= totalPages; i++) {
      //         pdf.setPage(i)
      //         pdf.setFontSize(10)
      //         pdf.setTextColor(150)
      //         pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
      //     } 
      // }).save()
    },
    generatePDF() {
      const element = document.getElementById('pdf') // replace with your own element ID
      const opt = {
        margin: 0.5,
        filename: 'myfile.pdf',
        image: { type: 'png', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
      }
      html2pdf().from(element).set(opt).save()
    },
    generateReport() {
      // this.$refs.html2Pdf.generatePdf();
    },
    async downloadCertificate() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });
      let formData = new FormData();
      formData.set('name', this.name);
      formData.set('qrData', this.qrData);
      formData.set('course_name', this.course_name);
      formData.set('father_name', this.father_name);
      formData.set('mother_name', this.mother_name);
      formData.set('registration_number', this.registration_number);
      formData.set('reference_number', this.reference_number);
      formData.set('hour', this.hour);
      formData.set('course_start', this.course_start);
      formData.set('course_end', this.course_end);
      formData.set('address', this.address);
      formData.set('BackgroundInfos', JSON.stringify(this.BackgroundInfos));
      formData.set('entityInfos', JSON.stringify(this.entityInfos));
      formData.set('unitOfCompetencyList', JSON.stringify(this.unitOfCompetencyList));


      await ApiService.post('certificate/download_certificate_pdf', formData)
        .then((response) => {
          ElNotification.closeAll();
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download Certificate',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },


    async initData() {
      ApiService.get("trainee/showDetailed/" + this.id)
        .then((response) => {
          this.name = response.data?.data?.trainee_name;
          this.qrData = `https://sp.tappware.com/verify-trainee/${response.data?.data?.registration_number}`;
          this.course_name = response.data?.data?.course_info?.course_name;
          this.course_id = response.data?.data?.course_info_id;
          this.father_name = response.data?.data?.father_name;
          this.mother_name = response.data?.data?.mother_name;
          this.registration_number = response.data?.data?.registration_number;
          this.reference_number = response.data?.data?.reference_number;
          this.hour = response.data?.data?.
            course_info?.hour;
          this.entity_description = response.data?.data?.
            entity_info?.entity_description;
          this.entity_name = response.data?.data?.
            entity_info?.entity_name;
          this.entity_id = response.data?.data?.entity_id;
          this.entity_short_name = response.data?.data?.
            entity_info?.entity_short_name;
          this.course_start = moment(response.data?.data?.
            batch_info?.start_date).format('DD MMMM, YYYY');
          this.course_end = moment(response.data?.data?.
            batch_info?.end_date).format('DD MMMM, YYYY');
          this.certification_date = "2022-01-01";
          this.institute_id = response.data?.data?.
            institute_info?.id;
          this.address = response.data?.data?.
            institute_info?.institute_name + ', ' +
            response.data?.data?.
              institute_info?.sub_district_info?.upazila_name_eng + ', ' +
            response.data?.data?.
              institute_info?.district_info?.district_name_eng;
          this.BackgroundImage();
          this.getEntityInfo();
          this.formSubmit();

          // //this.entity_id = VueCookieNext.getCookie("_entity_id");
          this.isLoading = false;
          console.log(response);
        })
        .catch((response) => {
          console.log(response);
        });


    },
    async formSubmit() {
      ApiService.get("course/unit-of-competency-list?course_id=" + this.course_id)
        .then((response) => {
          this.unitOfCompetencyList = response?.data?.data;
          console.log(response);
        })
        .catch((response) => {
          console.log(response);
        });
    },

    async BackgroundImage() {
      let formdata = new FormData();
      formdata.set('entity_id', this.entity_id);
      await ApiService.get("assessment/certificate/background?entity=" + this.entity_id + '&institute_id=' + this.institute_id
      ).then((response) => {
        if (response.data.data.length <= 0) {
          Swal.fire({
            title: 'Please Configure The Certificate Template First',
            icon: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Go To Certificate Configuration',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab
              location.replace('https://tms-seip-local.tappware.com/certificate/training-certificate-configuration');

            }
          });

        }
        this.BackgroundInfos = response.data.data;


        // this.backgroundImageCertificate = this.BackgroundInfos[0]?.background_image;

      })
        .catch(({ response }) => {

          console.log(response);
        });

    },
    async getEntityInfo() {
      let formdata = new FormData();
      formdata.set('entity_id', this.entity_id);
      await ApiService.get("assessment/certificate/getlogo?entity=" + this.entity_id
      )
        .then((response) => {
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() { },
});
